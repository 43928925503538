import {
  AppShell,
  Box,
  Center,
  Divider,
  NavLink,
  NavLinkProps,
  ScrollArea,
  Text,
  ThemeIcon,
  MantineTheme,
} from '@mantine/core';
import { useComputedColorScheme } from '@mantine/core';
import {
  IconFile,
  IconFileTypePdf,
  IconFlare,
  IconInfoCircle,
  IconListDetails,
  IconMail,
  IconMessageChatbot,
  IconPencil,
  IconTrophy,
  IconUser,
  IconWand,
  IconApps,
} from '@tabler/icons-react';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LinkProps,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume } from '../../models/Resume';
import { useResumesQuery } from '../../queries/ResumeQueries';
import { CreateResumeButton } from '../CreateResumeButton/CreateResumeButton';

// Animation variants
const navItemAnimation = {
  initial: { opacity: 0, x: -20 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
    },
  },
  exit: {
    opacity: 0,
    x: -20,
    transition: { duration: 0.2 },
  },
};

const navGroupAnimation = {
  animate: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
};

// Selection animation for active items
const activeItemAnimation = {
  layoutId: 'activeNavBackground',
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: 'spring',
    stiffness: 500,
    damping: 30,
  },
};

type MenuNavLinkProps = NavLinkProps &
  LinkProps & { setOpen: (open: boolean) => void };

const MenuNavLink: React.FC<MenuNavLinkProps> = ({ setOpen, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = !props.disabled && location.pathname === props.to;

  return (
    <motion.div
      variants={navItemAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      style={{ position: 'relative' }}
    >
      {isActive && (
        <motion.div
          {...activeItemAnimation}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 'var(--mantine-radius-md)',
            background: 'var(--mantine-primary-color-light)',
            opacity: 0.12,
            zIndex: 0,
          }}
        />
      )}
      <NavLink
        component={Link}
        active={isActive}
        onClick={() => {
          setOpen(false);
          if (props.to) {
            void navigate(props.to);
          }
        }}
        aria-current={location.pathname === props.to ? 'page' : undefined}
        aria-expanded={props.opened}
        tabIndex={0}
        styles={(theme: MantineTheme) => ({
          root: {
            position: 'relative',
            borderRadius: theme.radius.md,
            padding: '12px',
            transition: 'all 0.2s ease',
            '&[dataActive]': {
              backgroundColor: 'transparent',
              color: `var(--mantine-color-${theme.primaryColor}-7)`,
            },
            '&:hover': {
              backgroundColor: 'transparent',
              transform: 'translateX(4px)',
            },
          },
          label: {
            fontWeight: 500,
          },
        })}
        {...props}
      />
    </motion.div>
  );
};

const ApplicationMenu: React.FC<{
  setOpen: MenuNavLinkProps['setOpen'];
  resume: IResume;
  t: TFunction;
}> = ({ setOpen, resume, t }) => (
  <AppShell.Section>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Divider
        my="lg"
        label={
          <Text tt="uppercase" c="dimmed" fz="xs" fw={600}>
            {t('header.currentApplication')}
          </Text>
        }
      />
    </motion.div>
    <motion.div
      variants={navGroupAnimation}
      initial="initial"
      animate="animate"
    >
      <MenuNavLink
        leftSection={
          <ThemeIcon
            variant="light"
            size="lg"
            style={{
              background:
                'linear-gradient(135deg, var(--mantine-color-blue-6), var(--mantine-color-violet-6))',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <IconFile style={{ color: 'white', stroke: '1.5' }} />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}`}
        label={
          <Text fz="sm" fw={500} truncate="end">
            {resume.title}
          </Text>
        }
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon
            variant="light"
            size="md"
            style={{
              background: 'var(--mantine-color-blue-0)',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
            }}
          >
            <IconMail
              style={{ color: 'var(--mantine-color-blue-6)', stroke: '1.5' }}
            />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/letter`}
        label={t('view.coverLetterTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon
            variant="light"
            size="md"
            style={{
              background: 'var(--mantine-color-blue-0)',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
            }}
          >
            <IconInfoCircle
              style={{ color: 'var(--mantine-color-blue-6)', stroke: '1.5' }}
            />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/info`}
        label={t('view.infoTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon
            variant="light"
            size="md"
            style={{
              background: 'var(--mantine-color-blue-0)',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
            }}
          >
            <IconPencil
              style={{ color: 'var(--mantine-color-blue-6)', stroke: '1.5' }}
            />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/history`}
        label={t('view.historyTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon
            variant="light"
            size="md"
            style={{
              background: 'var(--mantine-color-blue-0)',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
            }}
          >
            <IconFileTypePdf
              style={{ color: 'var(--mantine-color-blue-6)', stroke: '1.5' }}
            />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/pdf`}
        label={t('view.pdfTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon
            variant="light"
            size="md"
            style={{
              background: 'var(--mantine-color-blue-0)',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
            }}
          >
            <IconMessageChatbot
              style={{ color: 'var(--mantine-color-blue-6)', stroke: '1.5' }}
            />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/interview`}
        label={t('view.interviewTab')}
        setOpen={setOpen}
      />
    </motion.div>
  </AppShell.Section>
);

export const AppNavbar: React.FC<{ setOpen: (open: boolean) => void }> = ({
  setOpen,
}) => {
  const { t } = useTranslation();
  const { resumeId } = useParams();
  const computedColorScheme = useComputedColorScheme('light');

  const { data: resumes } = useResumesQuery();

  const currentResume = useMemo(
    () => resumes?.resumes.find((r) => r.id === resumeId),
    [resumes, resumeId],
  );

  return (
    <AppShell.Navbar
      p="md"
      style={{
        background:
          computedColorScheme === 'dark'
            ? `var(--mantine-color-dark-8)`
            : `var(--mantine-color-gray-0)`,
        borderRight: `1px solid ${
          computedColorScheme === 'dark'
            ? `var(--mantine-color-dark-4)`
            : `var(--mantine-color-gray-2)`
        }`,
      }}
    >
      <ScrollArea
        scrollbars="y"
        styles={{
          viewport: { display: 'block', flexGrow: 1 },
          root: { display: 'block', flexGrow: 1 },
        }}
      >
        <Box
          style={{ display: 'block' }}
          maw={{ base: '100%', sm: 180, md: 280 }}
        >
          <AppShell.Section>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Center mb="xl">
                <CreateResumeButton
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Center>
            </motion.div>
          </AppShell.Section>

          <AnimatePresence mode="wait">
            <motion.div
              key="nav-sections"
              variants={navGroupAnimation}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <LayoutGroup>
                <AppShell.Section>
                  <MenuNavLink
                    leftSection={
                      <ThemeIcon
                        variant="light"
                        size="lg"
                        style={{
                          background:
                            'linear-gradient(135deg, var(--mantine-color-indigo-6), var(--mantine-color-blue-6))',
                          border: 'none',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        <IconApps style={{ color: 'white', stroke: '1.5' }} />
                      </ThemeIcon>
                    }
                    to={AppRouteURL.resumes.list}
                    label={
                      <Text size="sm" fw={500}>
                        {t('applications.pageTitle')}
                      </Text>
                    }
                    setOpen={setOpen}
                  />

                  <NavLink
                    href="#profile-nav-section"
                    label={t('header.profile')}
                    leftSection={
                      <ThemeIcon
                        variant="light"
                        size="lg"
                        style={{
                          background:
                            'linear-gradient(135deg, var(--mantine-color-cyan-6), var(--mantine-color-teal-6))',
                          border: 'none',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        <IconUser style={{ color: 'white', stroke: '1.5' }} />
                      </ThemeIcon>
                    }
                    defaultOpened={!currentResume}
                    aria-expanded={!currentResume}
                    tabIndex={0}
                    styles={(theme: MantineTheme) => ({
                      root: {
                        borderRadius: theme.radius.md,
                        padding: '12px',
                        transition: 'all 0.2s ease',
                        '&[dataExpanded]': {
                          backgroundColor: `var(--mantine-color-${theme.primaryColor}-0)`,
                        },
                      },
                      label: {
                        fontWeight: 500,
                      },
                    })}
                  >
                    <MenuNavLink
                      leftSection={
                        <ThemeIcon
                          variant="light"
                          size="md"
                          style={{
                            background: 'var(--mantine-color-teal-0)',
                            border: 'none',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                          }}
                        >
                          <IconUser
                            style={{
                              color: 'var(--mantine-color-teal-6)',
                              stroke: '1.5',
                            }}
                          />
                        </ThemeIcon>
                      }
                      to={AppRouteURL.profileTabBasic}
                      label={
                        <Text size="sm" id="app-header-profile-link">
                          {t('profile.personal')}
                        </Text>
                      }
                      setOpen={setOpen}
                    />
                    <MenuNavLink
                      leftSection={
                        <ThemeIcon
                          variant="light"
                          size="md"
                          style={{
                            background: 'var(--mantine-color-teal-0)',
                            border: 'none',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                          }}
                        >
                          <IconFlare
                            style={{
                              color: 'var(--mantine-color-teal-6)',
                              stroke: '1.5',
                            }}
                          />
                        </ThemeIcon>
                      }
                      to={AppRouteURL.profileTabIntro}
                      label={<Text size="sm">{t('profile.intro')}</Text>}
                      setOpen={setOpen}
                    />
                    <MenuNavLink
                      leftSection={
                        <ThemeIcon
                          variant="light"
                          size="md"
                          style={{
                            background: 'var(--mantine-color-teal-0)',
                            border: 'none',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                          }}
                        >
                          <IconListDetails
                            style={{
                              color: 'var(--mantine-color-teal-6)',
                              stroke: '1.5',
                            }}
                          />
                        </ThemeIcon>
                      }
                      to={AppRouteURL.profileTabTimeline}
                      label={
                        <Text size="sm">{t('profile.timelineLabel')}</Text>
                      }
                      setOpen={setOpen}
                    />
                  </NavLink>
                  <Divider
                    my="lg"
                    label={
                      <Text tt="uppercase" c="dimmed" fz="xs" fw={600}>
                        {t('navbar.aiHeader')}
                      </Text>
                    }
                  />
                  <MenuNavLink
                    leftSection={
                      <ThemeIcon
                        variant="light"
                        size="lg"
                        style={{
                          background:
                            'linear-gradient(135deg, var(--mantine-color-grape-5), var(--mantine-color-pink-4))',
                          border: 'none',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        <IconWand style={{ color: 'white', stroke: '1.5' }} />
                      </ThemeIcon>
                    }
                    to={AppRouteURL.analyze}
                    label={
                      <Text size="sm" id="app-header-profile-link">
                        {t('navbar.analyzer')}
                      </Text>
                    }
                    setOpen={setOpen}
                  />
                  <MenuNavLink
                    leftSection={
                      <ThemeIcon
                        variant="light"
                        size="lg"
                        style={{
                          background:
                            'linear-gradient(135deg, var(--mantine-color-yellow-3), var(--mantine-color-orange-3))',
                          border: 'none',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        <IconTrophy style={{ color: 'white', stroke: '1.5' }} />
                      </ThemeIcon>
                    }
                    to={AppRouteURL.achievements}
                    label={<Text size="sm">{t('navbar.achievements')}</Text>}
                    setOpen={setOpen}
                  />
                </AppShell.Section>

                {currentResume && (
                  <ApplicationMenu
                    setOpen={setOpen}
                    resume={currentResume}
                    t={t}
                  />
                )}
              </LayoutGroup>
            </motion.div>
          </AnimatePresence>
        </Box>
      </ScrollArea>
      <AppShell.Section>
        <Divider mb="sm" />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <Text fz="xs" c="dimmed" fw={500}>
            {t('login.copyrightFooter')}
          </Text>
        </motion.div>
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
