import { Carousel } from '@mantine/carousel';
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import {
  IconClipboard,
  IconScript,
  IconStars,
  IconTransfer,
  IconUniverse,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAnalysis } from '../../models/Analysis';
import { AnalysisCard } from './AnalysisCard';
import { ImportProfileModal } from './ImportProfileModal';

const AnalysisText: React.FC<{ text: string }> = ({ text }) => (
  <Box>
    {text
      .split('\n')
      .map((line, idx) => line && <p key={idx}>{line}</p>)
      .filter(Boolean)}
  </Box>
);

export const AnalysisSection: React.FC<{
  analysisData: IAnalysis;
}> = ({ analysisData: { analysis, documents, createdAt } }) => {
  const { t } = useTranslation();

  const [modalOpened, setModalOpened] = useState(false);

  const handleImportClick = () => {
    setModalOpened(true);
  };

  const handleModalClose = () => {
    setModalOpened(false);
  };

  return (
    <Box>
      <Grid>
        <Grid.Col span={{ base: 12, md: 8 }}>
          {analysis && (
            <Stack>
              <Paper shadow="md" radius="lg" p="xl">
                <Stack align="center">
                  <Button
                    mx="xl"
                    variant="gradient"
                    onClick={handleImportClick}
                    leftSection={<IconTransfer />}
                  >
                    {t('analyze.importButtonLabel')}
                  </Button>
                  <ImportProfileModal
                    opened={modalOpened}
                    onClose={handleModalClose}
                  />
                  <Title order={5} ta="center">
                    {t('analyze.importButtonCTA')}
                  </Title>
                </Stack>
              </Paper>
              <AnalysisCard
                title={t('analyze.result.general')}
                rating={analysis.general.rating}
                icon={<IconUniverse size={24} />}
              >
                <AnalysisText text={analysis.general.text} />
              </AnalysisCard>
              <AnalysisCard
                title={t('analyze.result.experience')}
                icon={<IconStars size={24} />}
                rating={analysis.experience.rating}
              >
                <AnalysisText text={analysis.experience.text} />
              </AnalysisCard>
              <AnalysisCard
                title={t('analyze.result.education')}
                icon={<IconScript size={24} />}
                rating={analysis.education.rating}
              >
                <AnalysisText text={analysis.education.text} />
              </AnalysisCard>
              <AnalysisCard
                title={t('analyze.result.tips')}
                icon={<IconClipboard size={24} />}
              >
                <Center>
                  <Carousel loop withIndicators w={380}>
                    {analysis.tips.map((tip, idx) => (
                      <Carousel.Slide key={idx}>
                        <Container fluid m="xl">
                          <Title ta="center" order={1}>
                            {idx + 1}
                          </Title>
                          <Text ta="center">{tip}</Text>
                        </Container>
                      </Carousel.Slide>
                    ))}
                  </Carousel>
                </Center>
              </AnalysisCard>
            </Stack>
          )}
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 4 }}>
          {documents && (
            <>
              {documents.map((doc, idx) => (
                <Paper key={idx} shadow="md" radius="lg" mb="md" p="md">
                  <Image src={doc.document ?? ''} alt="Resume" />
                  <Flex mx="xs" justify="right" mt={-30} mr="sm" mb={16}>
                    <Badge variant="default" size="xs">
                      {idx + 1}/{documents.length}
                    </Badge>
                  </Flex>
                </Paper>
              ))}
              <Center>
                <Badge size="sm" variant="default">
                  {t('analyze.importedBadge', {
                    date: dayjs(createdAt).fromNow(),
                  })}
                </Badge>
              </Center>
            </>
          )}
        </Grid.Col>
      </Grid>
      {(analysis || documents) && <Divider mt="lg" />}
    </Box>
  );
};
